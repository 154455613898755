import React, { useState, useEffect } from 'react'
import Head from 'next/head'
import { StickyContainer, Sticky } from 'react-sticky'
import getConfig from 'next/config'
const { publicRuntimeConfig } = getConfig()
const { ANALYTICS } = publicRuntimeConfig

const Layout = ctx => {
  const [showMenu, setShowMenu] = useState(false)
  const [vanPosition, setVanPosition] = useState(0)
  const { children, pageTitle } = ctx

  function toggleMenu(e) {
    e.preventDefault()
    setShowMenu(!showMenu)
  }

  function hideMenu() {
    setShowMenu(false)
  }

  useEffect(function mount() {
    function onScroll() {
      const h = document.documentElement
      const b = document.body
      const st = 'scrollTop'
      const sh = 'scrollHeight'

      const percent = ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100
      const width = document.body.clientWidth
      setVanPosition(width * (percent / 100))
    }

    window.addEventListener('scroll', onScroll)

    return function unMount() {
      window.removeEventListener('scroll', onScroll)
    }
  })

  return (
    <div>
      <Head>
        <title>{pageTitle || 'Roady Coffee Van - Mobile Coffee van in Halifax Calderdale'}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bulma@0.9.0/css/bulma.min.css" />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.14.0/css/all.min.css"
        />
        <meta
          name="description"
          content="Roady Coffee Van. Speciality coffee on the streets of Halifax and Calderdale"
        />
        <meta property="og:title" content="Roady Coffee Van" />
        <meta
          property="og:description"
          content="Mobile coffee van hire and popup coffee bar on the streets of Halifax and Calderdale!"
        />
        <meta property="og:image" content="https://www.roadycoffee.com/square.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,400;0,700;1,200;1,400;1,700&display=swap"
          rel="stylesheet"
        />
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-178686716-1" />
        {ANALYTICS ? (
          <script
            dangerouslySetInnerHTML={{
              __html: `
                            window.dataLayer = window.dataLayer || [];
                            function gtag(){dataLayer.push(arguments);}
                            gtag('js', new Date());
                            gtag('config', 'UA-178686716-1');`,
            }}
          />
        ) : null}
      </Head>
      <StickyContainer>
        <Sticky>
          {({ style, distanceFromTop }) => (
            <section style={style} className={distanceFromTop < -10 ? 'header smaller' : 'header'}>
              <header className="container">
                <nav className="navbar" role="navigation" aria-label="main navigation">
                  <div className="navbar-brand">
                    <a className="navbar-item" href="/#">
                      {distanceFromTop < -10 ? (
                        <img className="logo" src="/logo-words.png" alt="Roady Coffee Van" />
                      ) : (
                        <img className="logo" src="/logo.png" alt="Roady Coffee Van" />
                      )}
                    </a>
                    <a
                      role="button"
                      href="#"
                      onClick={toggleMenu}
                      className={showMenu ? 'navbar-burger is-active' : 'navbar-burger'}
                      aria-label="menu"
                      aria-expanded="false"
                    >
                      <span aria-hidden="true" />
                      <span aria-hidden="true" />
                      <span aria-hidden="true" />
                    </a>
                  </div>
                  <div className={showMenu ? 'navbar-menu is-active' : 'navbar-menu navbar-end'}>
                    <a onClick={hideMenu} className="navbar-item" href="/#our-story">
                      Our Story
                    </a>
                    <a onClick={hideMenu} className="navbar-item" href="/#events">
                      Hire
                    </a>
                    <a onClick={hideMenu} className="navbar-item" href="/#contact-us">
                      Contact Us
                    </a>
                  </div>
                </nav>
              </header>
              {distanceFromTop < -10 ? (
                <img
                  src="/van.png"
                  className="van"
                  alt="van"
                  style={{ left: `${vanPosition - 40}px` }}
                />
              ) : null}
            </section>
          )}
        </Sticky>
        <main className="main">{children}</main>
        <footer className="footer">
          <div className="content container">
            <div className="columns">
              <div className="column footer-section">
                <h1>Main Navigation</h1>
                <ul>
                  <li>
                    <a href="/#day-to-day">Where To Find Us</a>
                  </li>
                  <li>
                    <a href="/#our-story">Our Story</a>
                  </li>
                  <li>
                    <a href="/#events">Services</a>
                    <ul>
                      <li>
                        <a href="/wedding-coffee-van-hire">Weddings</a>
                      </li>
                      <li>
                        <a href="/party-and-festival-coffee-van-hire">Parties</a>
                      </li>
                      <li>
                        <a href="/tv-set-catering">TV Set Catering</a>
                      </li>
                      <li>
                        <a href="corporate-events">Corporate Events</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="/#contact-us">Contact Us</a>
                  </li>
                </ul>
              </div>
              <div className="column footer-section">
                <h1>Follow Us</h1>
                <ul className="social-links">
                  <li>
                    <a href="https://www.instagram.com/roadycoffee/">
                      <i className="fab fa-instagram" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.facebook.com/roadycoffee">
                      <i className="fab fa-facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.twitter.com/roadycoffee">
                      <i className="fab fa-twitter" />
                    </a>
                  </li>
                </ul>
                <h1>Contact</h1>
                <ul>
                  <li>
                    <a href="mailto://roadycoffeevan@outlook.com">roadycoffeevan@outlook.com</a>
                  </li>
                </ul>
                <h1>
                  Calderdale businesses we{' '}
                  <span role="img" aria-label="love">
                    ❤️
                  </span>
                </h1>
                <ul>
                  <li>
                    <a href="/calderdale-businesses">Our favourite independents</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="has-text-centered">Copyright 2020 © RoadyCoffee.com</div>
          </div>
        </footer>
      </StickyContainer>
      <style jsx>{`
        @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@1,300&display=swap');
        .footer {
          background-color: white;
        }
        .footerLinks {
          margin-bottom: 1em;
          margin-left: 0;
        }
        .footerLinks li {
          display: inline-block;
          border-left: solid 1px #4a4a4a;
          padding: 0em 1em;
        }
        .footerLinks li:first-child {
          border-left: 0;
        }
        .logo {
          width: 300px;
          max-height: 110px;
        }
        .header {
          z-index: 100;
          background-color: white;
          padding: 0.5em;
        }
        .smaller {
          border-bottom: 4px #616161 solid;
          padding-bottom: 1.5em;
        }
        .smaller .logo {
          width: 175px;
          max-height: 71px;
        }
        .navbar-burger {
          position: relative;
          top: 10px;
        }
        .footer-section h1 {
          font-size: 1em;
          border-bottom: solid 5px #6d6d6d;
          padding-bottom: 5px;
        }
        .footer-section ul {
          list-style: none;
          margin-left: 0;
        }
        .footer-section ul ul {
          margin-left: 1em;
        }
        .social-links li {
          display: inline;
          font-size: 2em;
          padding-right: 1em;
        }
        @media only screen and (min-width: 1024px) {
          .navbar-menu {
          }
        }
        @media only screen and (max-width: 500px) {
          .logo {
            width: 200px;
            max-height: 71px;
          }
        }
        .van {
          position: fixed;
          width: 40px;
        }
      `}</style>
      <style global jsx>{`
        @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap');
        body {
          font-family: 'Poppins', sans-serif;
        }
        p {
          margin-bottom: 1em;
        }
        html {
          scroll-behavior: smooth;
        }
      `}</style>
    </div>
  )
}

export default Layout
